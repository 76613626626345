export const journalCategories = ["all", "dev", "art", "stories", "misc"];

export const artCategories = [
  "cg-paint-right",
  "cg-paint-left",
  "cg-graph",
  "stories",
  "trad-graph",
  "trad-paint",
];
